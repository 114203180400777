<template>
  <div v-if="companyItemTypes">
    <div v-if="!isSide">
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th class="is-title">Category</th>
            <th v-for="(assessment) in allAssessments"
              :key="assessment.assessmentID"
              class="has-text-right is-subtotal">
              {{ assessment.subQuoteNo === 0 ? 'Main' : `Supp-${assessment.subQuoteNo}` }}
            </th>
            <th v-if="allAssessments.length > 1"
              class="has-text-right is-subtotal">
              Summary
            </th>
            <th v-if="allAssessments.length === 0">Main</th>
          </tr>
        </thead>
        <tfoot :class="[allAssessments.length > 1 ? 'has-multiple-column-totals' : 'has-multiple-totals']">
          <tr>
            <th class="has-text-right">Total excl. GST</th>
            <th v-for="(assessment) in allAssessments"
              :key="assessment.assessmentID"
              class="has-text-right has-text-success">
              {{ getAssessmentTotal(assessment) | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length > 1"
              class="has-text-right has-text-success">{{ getGrandAssessmentTotal() | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length === 0"
              class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
          </tr>
          <tr>
            <th class="has-text-right">GST</th>
            <th v-for="(assessment) in allAssessments"
              :key="assessment.assessmentID"
              class="has-text-right has-text-success">
              {{ getAssessmentTotal(assessment) * (value.gstRate/100) | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length > 1"
              class="has-text-right has-text-success">{{ getGrandAssessmentTotal() * (value.gstRate/100) | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length === 0"
              class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
          </tr>
          <tr>
            <th class="has-text-right">Total incl. GST</th>
            <th v-for="(assessment) in allAssessments"
              :key="assessment.assessmentID"
              class="has-text-right has-text-success">
              {{ getAssessmentTotal(assessment) * (value.gstRate/100) + getAssessmentTotal(assessment) | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length > 1"
              class="has-text-right has-text-success">{{ getGrandAssessmentTotal() * (value.gstRate/100) + getGrandAssessmentTotal() | formatCurrency($userInfo.locale) }}
            </th>
            <th v-if="allAssessments.length === 0"
              class="has-text-right">{{ 0 | formatCurrency($userInfo.locale) }}</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="(itemType) in companyItemTypes"
            :key="itemType.itemCategoryId">
            <td>
              {{ getDisplayName(itemType.itemCategoryType) }}
            </td>
            <td v-for="assessment in allAssessments"
              :key="assessment.assessmentID"
              class="has-text-right"> {{ getAssessmentTotal(assessment, itemType.itemCategoryType) | formatNumber($userInfo.locale) }}</td>
            <td v-if="allAssessments.length > 1"
              class="has-text-right">
              {{ getGrandAssessmentTotal(itemType.itemCategoryType) | formatNumber($userInfo.locale) }}
            </td>
            <td v-if="allAssessments.length === 0"
              class="has-text-right">{{ 0 | formatNumber($userInfo.locale) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--   Side View   -->
    <div v-else>
      <div class="label-text has-text-primary mb-2">
        <span class="text">Cost Summary</span>
      </div>
      <div class="tabs is-boxed is-small mb-1">
        <ul class="dark">
          <li v-for="(assessment) in allAssessments"
            :key="assessment.assessmentID"
            :class="{ 'is-active' : selectedAssessment?.subQuoteNo === assessment.subQuoteNo}">
            <a @click="selectedAssessment = assessment">
              <span> {{ getSummaryTitle(assessment) }}</span>
            </a>
          </li>
          <li v-if="allAssessments.length > 1"
            :class="{ 'is-active' : selectedAssessment === null}">
            <a @click="selectedAssessment = null">
              <span>Summary</span>
            </a>
          </li>
        </ul>
      </div>
      <table class="table is-narrow is-fullwidth cost-summary">
        <tbody>
          <tr class="no-border"
            v-for="(itemType) in companyItemTypes"
            :key="itemType.itemCategoryId">
            <td>
              {{ getDisplayName(itemType.itemCategoryType) }}
            </td>
            <td class="has-text-right"> {{ getAssessmentTotal(selectedAssessment, itemType.itemCategoryType) | formatNumber($userInfo.locale) }}</td>
          </tr>

          <tr class="top-border mt-2">
            <td>
              <span class="text has-text-weight-bold">Sub Total</span>
            </td>
            <td class="has-text-right">
              <span class="text has-text-weight-bold">
                {{ getAssessmentTotal(selectedAssessment) | formatCurrency($userInfo.locale) }}
              </span>
            </td>
          </tr>
          <tr class="no-border">
            <td>
              <span class="text has-text-weight-bold">
                GST
              </span>
            </td>
            <td class="has-text-right">
              <span class="text has-text-weight-bold">
                {{ getAssessmentTotal(selectedAssessment) * (value.gstRate/100) | formatCurrency($userInfo.locale) }}
              </span>
            </td>
          </tr>
          <tr class="no-border">
            <td>
              <span class="text has-text-weight-bold">Total</span>
            </td>
            <td class="has-text-right">
              <span class="text has-text-weight-bold">
                {{ getAssessmentTotal(selectedAssessment) * (value.gstRate/100) + getAssessmentTotal(selectedAssessment) | formatCurrency($userInfo.locale) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { ItemCategoryTypes } from '@/enums'
import { QuoteAssessmentMixin } from './mixins'

export default {
  name: 'QuoteDetailAssessmentSummary',
  mixins: [NumberFiltersMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    isSide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAssessment: null,
      draftAssessment: null
    }
  },
  computed: {
    companyItemTypes() {
      return this.$company.itemCategories.filter((i) => i.enabled)
    },
    allAssessments() {
      if (!this.value.quoteAssessments) {
        return []
      }
      const all = this.value.quoteAssessments.filter((a) => a.deleted !== true)
      if (this.draftAssessment) {
        all.push(this.draftAssessment)
      }
      return all
    }
  },
  created() {
    this.createDraftAssessment()
    this.selectedAssessment = this.allAssessments.length === 1 ? this.allAssessments[0] : null
  },
  mounted() {},
  methods: {
    getSummaryTitle(assessment) {
      if (assessment.subQuoteNo === 0) {
        if (this.allAssessments.length > 1) {
          return 'Main'
        }
        return 'Summary'
      }
      return 'Add-' + assessment.subQuoteNo
    },
    getAssessmentTotal(assessment, itemCategoryType = null) {
      if (assessment === null) {
        return this.getGrandAssessmentTotal(itemCategoryType)
      }
      if (assessment.authorisedAssessment) {
        let items = itemCategoryType
          ? assessment.authorisedAssessment.assessmentQuoteItems.filter((i) => i.itemType === itemCategoryType)
          : assessment.authorisedAssessment.assessmentQuoteItems
        return this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      } else if (assessment.submittedAssessment) {
        let items = itemCategoryType
          ? assessment.submittedAssessment.assessmentQuoteItems.filter((i) => i.itemType === itemCategoryType)
          : assessment.submittedAssessment.assessmentQuoteItems
        return this.getAssessmentItemsDeltaTotal(items, this.value.quotingMethod)
      }
    },
    getGrandAssessmentTotal(itemCategoryType = null) {
      return this.allAssessments.reduce((acc, assessment) => {
        return acc + this.getAssessmentTotal(assessment, itemCategoryType)
      }, 0)
    },
    getQuoteTotal(itemCategoryType = null) {
      if (itemCategoryType === null) {
        return this.value.totalExGst
      }
      switch (itemCategoryType) {
        case ItemCategoryTypes.RR:
          return this.value.rrTotal
        case ItemCategoryTypes.RWA:
          return this.value.rwaTotal
        case ItemCategoryTypes.REP:
          return this.value.repairTotal
        case ItemCategoryTypes.PAINT:
          return this.value.paintTotal
        case ItemCategoryTypes.FIBER:
          return this.value.fgTotal
        case ItemCategoryTypes.CRUSH:
          return this.value.crushTotal
        case ItemCategoryTypes.CD:
          return this.value.cdTotal
        case ItemCategoryTypes.MECH:
          return this.value.mechTotal
        case ItemCategoryTypes.PART:
          return this.value.partTotal
        case ItemCategoryTypes.OPG:
          return this.value.opgTotal
        case ItemCategoryTypes.MISC:
          return this.value.miscTotal
        case ItemCategoryTypes.SUBL:
          return this.value.sublTotal
        case ItemCategoryTypes.PDRRR:
          return this.value.pdrRrTotal
        case ItemCategoryTypes.PDRRWA:
          return this.value.pdrRwaTotal
        default:
          break
      }
    },
    getDisplayName(itemCategoryType) {
      if (this.companyItemTypes) {
        const itemType = this.companyItemTypes.find((i) => i.itemCategoryType === itemCategoryType)
        if (itemType) {
          return itemType.displayName
        }
      }
      return itemCategoryType
    },
    createDraftAssessment() {
      const items = [...this.value.labours, ...this.value.others, ...this.value.parts, ...this.value.opgs, ...this.value.miscs, ...this.value.sublets]
      const itemsChanged = this.itemsChanged(items, this.value.quotingMethod)
      if (itemsChanged.length > 0) {
        this.draftAssessment = this.createAssessment(itemsChanged, this.value.quoteAssessments.length, this.value.assessorName)
      } else {
        this.draftAssessment = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
th.is-subtotal {
  width: 7em;
}
th.is-title {
  width: 12em;
}

.cost-summary {
  background: transparent;
  color: white;
  border: none;
}
table {
  &.is-narrow {
    height: 1rem;
  }
  tr.no-border td {
    border: none;
  }
  tr.top-border {
    td {
      border: none;
      border-top: solid 1px rgba(255, 255, 255, 0.2);
      vertical-align: bottom;
    }
    height: 2.5rem;
  }
}
</style>
